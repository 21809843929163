import { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import axios from 'axios'
import {
  Role,
  useLazyGetRolesQuery,
} from 'manage-tritag/services/api/endpoints/roles'
import {
  Button,
  Fade,
  FormControl,
  InputLabel,
  Modal,
  Select,
  styled,
} from '@mui/material'
import { isEmptyObj } from 'manage-tritag/utils/string'
import { toast } from 'react-toastify'
import { useAuth0 } from '@auth0/auth0-react'

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBody = styled('div')`
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  min-width: 600px;
`

interface PermissionModalProps {
  user: any
  setModal: () => void
}

export interface Access {
  type: number
  entityId: string
  logo: string
  label: string
  active?: boolean
}

interface MetaData {
  access: Access[]
  activeRole: Access | undefined
}

const PermissionModal = ({ user, setModal }: PermissionModalProps) => {
  const { user: authUser } = useAuth0()
  const [level, setLevel] = useState<string>('')
  const [entity, setEntity] = useState<Role | undefined>(undefined)
  const [roles, setRoles] = useState<Role[]>([])
  const [roleTrigger, roleResult] = useLazyGetRolesQuery()
  const [isLoading, setisLoading] = useState(false)
  const [activeRole, setActiveRole] = useState<Access | undefined>(undefined)

  const { data: rolesData } = roleResult

  useEffect(() => {
    if (rolesData) {
      setRoles(rolesData)
    }
  }, [rolesData])

  useEffect(() => {
    if (authUser) {
      setActiveRole(
        authUser['https://tritagrugby.com/user_metadata'].activeRole,
      )
    }
  }, [authUser])

  const handleLevelChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target
    setLevel(value as string)
    roleTrigger(value as string)
  }

  const handleEntityChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = roles.find((i: any) => i._id === e.target.value)
    setEntity(value)
  }

  const handleAddUserRole = async () => {
    setisLoading(true)
    const token = Cookie.get('token')
    let type: number = 0
    switch (level) {
      case 'master':
        type = 1
        break
      case 'sub-master':
        type = 2
        break

      case 'association':
        type = 3
        break

      default:
        type = 0
    }

    let metaData: MetaData = {
      access: [],
      activeRole: undefined,
    }

    if (isEmptyObj(user.user_metadata)) {
      metaData = {
        access: [
          {
            type,
            entityId: entity?._id || '',
            label: entity?.label || '',
            logo: entity?.logo || '',
            active: true,
          },
        ],
        activeRole: {
          type,
          entityId: entity?._id || '',
          label: entity?.label || '',
          logo: entity?.logo || '',
          active: true,
        },
      }
    } else {
      // check if role already in access array
      if (
        user.user_metadata.access.filter((e: Access) => e.type === type)
          .length > 0 &&
        user.user_metadata.access.filter(
          (e: Access) => e.entityId === entity?._id,
        ).length > 0
      ) {
        setModal()
        return toast.error('Permission already exist')
      }
      const accessArray = [
        ...user.user_metadata.access,
        {
          type,
          entityId: entity?._id || '',
          label: entity?.label || '',
          logo: entity?.logo || '',
          active: true,
        },
      ]
      metaData = {
        access: accessArray,
        activeRole: user.user_metadata.activeRole,
      }
    }

    const options = {
      method: 'PATCH',
      url: `https://api.tritagrugby.com/api/users/${user.user_id}`,
      headers: {
        // this is only a test token
        mahanginako: token,
        'content-type': 'application/json',
      },
      data: {
        user_metadata: metaData,
      },
    }

    await axios
      .request(options)
      .then(() => {
        toast.success('Role added succesfully')
        setModal()
        setisLoading(false)
      })
      .catch(() => {
        setisLoading(false)
        toast.success('Something went wrong, please try again.')
      })
  }

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={() => setModal()}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalBody>
          <p id="transition-modal-title" className="montserrat bold">
            Add user permission
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <FormControl
              variant="filled"
              // className={classes.formControl}
            >
              <InputLabel shrink htmlFor="filled-age-native-simple">
                Level *
              </InputLabel>
              <Select
                native
                sx={{ minWidth: '300px', marginRight: '1rem' }}
                value={level}
                onChange={handleLevelChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
              >
                <option aria-label="None" value="" selected>
                  Select Level
                </option>
                {activeRole && [1].includes(activeRole.type) && (
                  <option value="master">Master</option>
                )}
                {activeRole && [1, 2].includes(activeRole.type) && (
                  <option value="sub-master">Sub Master</option>
                )}
                {activeRole && [1, 2, 3].includes(activeRole.type) && (
                  <option value="association">Association</option>
                )}
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              //  className={classes.formControl}
            >
              <InputLabel shrink htmlFor="filled-age-native-simple">
                Entity *
              </InputLabel>
              <Select
                native
                sx={{ minWidth: '300px' }}
                onChange={handleEntityChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
              >
                <option aria-label="None" value="" selected>
                  Select Entity
                </option>

                {roles.map(role => (
                  <option key={role._id} value={role._id}>
                    {role.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{ float: 'right', marginTop: '2.5rem' }}>
            <Button
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={() => {
                setModal()
              }}
              style={{
                fontSize: '0.8rem',
                marginRight: '1rem',
                background: 'white',
                color: '#000',
                border: '1px solid #008174',
                borderRadius: 'borderRadius',
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={handleAddUserRole}
              style={{
                fontSize: '0.8rem',
                padding: '0.4rem 1.5rem 0.4rem 1.5rem',
              }}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

export default PermissionModal
