import { api } from '..'

export interface User {
  user_id: string
  user_metadata: any
  family_name: string
  given_name: string
  name: string
  email: string
  email_verified: string
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type UsersResponse = User[]

export const usersApi = api.injectEndpoints({
  endpoints: build => ({
    getUsers: build.query<UsersResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: '/api/users',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
    }),
    addUser: build.mutation<User, Partial<User>>({
      query: body => ({
        url: 'users',
        method: 'POST',
        body,
      }),
    }),
    getUser: build.query<User, string>({
      query: id => `api/users/${id}`,
    }),
    updateUser: build.mutation<void, Pick<User, '_id'> & Partial<User>>({
      query: ({ _id, ...patch }) => ({
        url: `users/${_id}`,
        method: 'PUT',
        body: patch,
      }),
      async onQueryStarted({ _id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUser', _id, draft => {
            Object.assign(draft, patch)
          }),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    deleteUser: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `users/${id}`,
          method: 'DELETE',
        }
      },
    }),
  }),
})

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi
