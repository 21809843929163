import { api } from '..'

export interface Role {
  _id: string
  label: string
  ttr_id: string
  logo: string
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type RolesResponse = Role[]

export const rolesApi = api.injectEndpoints({
  endpoints: build => ({
    getRoles: build.query<RolesResponse, string>({
      query: level => {
        return {
          url: `roles/${level}`,
        }
      },
      providesTags: ['roles'],
    }),
    addRoles: build.mutation({
      query: body => ({
        url: 'roles',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['roles'],
    }),
    updateRoles: build.mutation({
      query: body => ({
        url: `roles/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['roles'],
    }),
    deleteRoles: build.mutation({
      query: id => ({
        url: `roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['roles'],
    }),
  }),
})

export const {
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useAddRolesMutation,
  useUpdateRolesMutation,
  useDeleteRolesMutation,
} = rolesApi
