import axios from 'axios'
import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { PageProps } from 'gatsby'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import {
  useLazyGetUserQuery,
  User,
} from 'manage-tritag/services/api/endpoints/users'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import AddUserPermission, {
  Access,
} from 'manage-tritag/components/modal/user_permission'
import { roleLabel } from 'manage-tritag/utils/string'
import Cookie from 'js-cookie'
import { toast } from 'react-toastify'
import DeletePermissionModal from 'manage-tritag/components/modal/delete_permission_modal'
import Spinner from 'manage-tritag/components/loading/spinner'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const UserManagementEditForm = (props: PageProps) => {
  const { params } = props
  const [trigger, result] = useLazyGetUserQuery()
  const [permissionModal, setPermissionModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [roleToDelete, setRoleToDelete] = useState<Access | undefined>(
    undefined,
  )
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  const { isLoading, data: userData } = result

  useEffect(() => {
    if (userData) {
      reset({
        firstName: userData?.given_name,
        lastName: userData?.family_name,
        email: userData?.email,
      })
    }
  }, [reset, userData])

  useEffect(() => {
    trigger(params?.id)
  }, [])

  const closeModal = () => {
    setPermissionModal(false)
    trigger(params?.id)
  }

  const onSubmit: SubmitHandler<User> = createData => {
    // @TODO - This looks unfinished?
    console.log('onSubmit', createData)
  }

  const handleDelete = async () => {
    const token = Cookie.get('token')
    if (userData && roleToDelete) {
      try {
        const accessArray = userData.user_metadata.access.filter(
          (role: Access) => role.entityId !== roleToDelete?.entityId,
        )
        const metaData = {
          access: accessArray,
          activeRole:
            userData.user_metadata.activeRole.entityId === roleToDelete.entityId
              ? accessArray.length
                ? accessArray[0]
                : null
              : userData.user_metadata.activeRole,
        }

        const options = {
          method: 'PATCH',
          url: `https://api.tritagrugby.com/api/users/${userData.user_id}`,
          headers: {
            // this is only a test token
            mahanginako: token,
            'content-type': 'application/json',
          },
          data: {
            user_metadata: metaData,
          },
        }

        await axios
          .request(options)
          .then(() => {
            toast.success('Role removed succesfully')
            setDeleteModal(false)
            trigger(params?.id)
          })
          .catch(() => {
            toast.error('Something went wrong, please try again.')
            setDeleteModal(false)
            trigger(params?.id)
          })
      } catch (error) {
        toast.error('Something went wrong, please try again.')
        console.error(error)
        // window.location.href = '/'
      }
    }
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={4}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography color="primary" variant="h6">
              User details
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  label="First Name"
                  variant="filled"
                  size="small"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  label="Last Name"
                  variant="filled"
                  size="small"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Email"
                  variant="filled"
                  size="small"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item xs={4} spacing={2}>
            <Grid item flexGrow={1}>
              <Typography color="primary" variant="h6">
                User permission
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setPermissionModal(true)
                }}
              >
                Add role
              </Button>
            </Grid>
            {userData &&
              userData.user_metadata &&
              userData.user_metadata.access &&
              userData.user_metadata.access.map(
                (role: Access) =>
                  role.active && (
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Item
                        sx={{ width: '100%', padding: '1rem 0rem' }}
                        elevation={6}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={3}>
                            {role.logo ? (
                              <img src={role.logo} height="55" alt="" />
                            ) : (
                              'No Logo'
                            )}
                          </Grid>
                          <Grid
                            container
                            item
                            xs
                            flexGrow={1}
                            direction="column"
                            alignItems="flex-start"
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                marginLeft: '0.5rem',
                                lineHeight: 2,
                                fontWeight: 'bold',
                              }}
                            >
                              {roleLabel(role.type)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ marginLeft: '0.5rem', lineHeight: 2 }}
                            >
                              {role.label}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="delete"
                              color="primary"
                              onClick={() => {
                                setDeleteModal(true)
                                setRoleToDelete(role)
                              }}
                            >
                              <DeleteIcon
                                sx={{ color: 'red', fontSize: '3rem' }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                  ),
              )}
          </Grid>
        </Grid>
        <Box sx={{ display: 'none' }}>
          <input type="submit" />
        </Box>
      </Grid>
      {permissionModal && userData && (
        <AddUserPermission user={userData} setModal={closeModal} />
      )}

      {deleteModal && roleToDelete && (
        <DeletePermissionModal
          setModal={setDeleteModal}
          name={`${userData?.given_name} ${userData?.family_name}`}
          role={roleToDelete}
          handleDelete={handleDelete}
        />
      )}
    </Box>
  )
}

export default UserManagementEditForm
