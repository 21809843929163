import { FC } from 'react'
import { Button, Fade, Modal, styled } from '@mui/material'
import { roleLabel } from 'manage-tritag/utils/string'
import Spinner from '../loading/spinner'
import { Access } from './user_permission'

interface DeleteAssociationModalProps {
  setModal: (value: boolean) => void
  deleteLoading?: boolean
  handleDelete: () => void
  name: string
  role: Access
}

const DeletePermissionModal: FC<DeleteAssociationModalProps> = ({
  setModal,
  deleteLoading,
  handleDelete,
  name,
  role,
}) => {
  const ModalContainer = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const ModalBody = styled('div')`
    background-color: #fff;
    border-radius: 5px;
    padding: 1.3rem;
    max-width: 600px;
  `

  const StyledButton = styled(Button)`
    font-size: 0.8rem;
  `

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={() => setModal(false)}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalBody>
          <p
            id="transition-modal-title"
            className="montserrat bold"
            style={{ fontWeight: 'bold' }}
          >
            Remove user permissions
          </p>
          <p id="transition-modal-description" className="montserrat">
            Are you sure you want to remove the following user permissions:
          </p>
          <p
            id="transition-modal-description"
            className="montserrat"
            style={{ fontWeight: 'bold', lineHeight: 0.8 }}
          >
            {name}
          </p>
          <p
            id="transition-modal-description"
            className="montserrat"
            style={{ fontWeight: 'bold', lineHeight: 0.8 }}
          >
            {roleLabel(role.type)}
          </p>
          <p
            id="transition-modal-description"
            className="montserrat"
            style={{ fontWeight: 'bold', lineHeight: 0.8 }}
          >
            {role.label}
          </p>
          <div style={{ float: 'right', marginTop: '2.5rem' }}>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={() => setModal(false)}
              style={{
                marginRight: '1rem',
                background: 'white',
                color: '#000',
                border: '1px solid #008174',
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={handleDelete}
              style={{
                padding: '0.4rem 1.5rem 0.4rem 1.5rem',
                background: 'red',
              }}
            >
              {deleteLoading ? <Spinner size={22} /> : 'DELETE'}
            </StyledButton>
          </div>
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

DeletePermissionModal.defaultProps = {
  deleteLoading: false,
}

export default DeletePermissionModal
