import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import UserManagementEditForm from 'manage-tritag/components/pages/user-management/user-management-edit-form'

const UserManagementEditPage = (props: PageProps) => {
  return (
    <Layout>
      <UserManagementEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(UserManagementEditPage)
